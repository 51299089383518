import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Avatar } from '@mui/material';


function ResponsiveAppBar() {

  return (
    <AppBar position="static" sx={{backgroundColor: "#000000"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              width: 200, 
            }}
            src={require('./mascom_white.png')}
            variant='square'
          />
          <Avatar
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' } ,
              width: 150  
            }}
            src={require('./mascom_white.png')}
            variant='square'
          />
          <Box sx={{ flexGrow: 1, display:  'flex'  }}>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
