const data = [

{
    "p_id": 101,
    "p_name": "Batch Tshirt 2024-25 (Special Edition Combo)",
    "p_price": 1000,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Combo/BackNyxens.jpg"),require("../Clothing/Combo/FrontNyxens.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "nyxens",
},   
{
    "p_id": 102,
    "p_name": "Batch Tshirt 2024-25 (Black-Grey) Special Edition",
    "p_price": 550,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Special/BackTitans.jpg"),require("../Clothing/Special/FrontTitans.jpg"),require("../Clothing/Special/FlyFrontTitans.jpg"),require("../Clothing/Special/FlyBackTitans.jpg"),require("../Clothing/Special/RightSleeveTitans.jpg"),require("../Clothing/Special/2024-26.jpg"),require("../Clothing/Special/Back.jpg"),require("../Clothing/Special/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "titans",
}
,
{
    "p_id": 103,
    "p_name": "Batch Tshirt 2024-25 (SkyBlue)",
    "p_price": 500,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Blue/BackTitans.jpg"),require("../Clothing/Blue/FrontTitans.jpg"),require("../Clothing/Blue/Titans.jpg"),require("../Clothing/Blue/2024-26.jpg"),require("../Clothing/Blue/Back.jpg"),require("../Clothing/Blue/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "titans",
},
{
    "p_id": 104,
    "p_name": "Batch Tshirt 2024-25 (Maroon)",
    "p_price": 500,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Maroon/TitansBack.jpg"),require("../Clothing/Maroon/TitansFront.jpg"),require("../Clothing/Maroon/RightSleeveTitans.jpg"),require("../Clothing/Maroon/2024-26.jpg"),require("../Clothing/Maroon/Back.jpg"),require("../Clothing/Maroon/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "titans",
},
{
    "p_id": 105,
    "p_name": "Batch Tshirt 2024-25 (White)",
    "p_price": 500,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/White/BackTitans.jpg"),require("../Clothing/White/FrontTitans.jpg"),require("../Clothing/White/RightSleeveTitans.jpg"),require("../Clothing/White/2024-26.jpg"),require("../Clothing/White/Back.jpg"),require("../Clothing/White/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "titans",
}
,
{
    "p_id": 106,
    "p_name": "Batch Tshirt 2024-25 (Wild Orchid)",
    "p_price": 500,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Pink/BackTitans.jpg"),require("../Clothing/Pink/FrontTitans.jpg"),require("../Clothing/Pink/RightSleeveTitans.jpg"),require("../Clothing/Pink/2024-26.jpg"),require("../Clothing/Pink/Back.jpg"),require("../Clothing/Pink/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "titans",
},
{
    "p_id": 107,
    "p_name": "Batch Tshirt 2024-25 (Special Edition Combo))",
    "p_price": 1000,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Combo/BackTitans.jpg"),require("../Clothing/Combo/FrontTitans.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "titans",
},
{
    "p_id": 108,
    "p_name": "Batch Tshirt 2024-25 (Grey-Black) Special Edition",
    "p_price": 550,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Special/BackNyxens.jpg"),require("../Clothing/Special/FrontNyxens.jpg"),require("../Clothing/Special/FlyFrontNyxens.jpg"),require("../Clothing/Special/FlyBackNyxens.jpg"),require("../Clothing/Special/RightSleeve.jpg"),require("../Clothing/Special/2023-25.jpg"),require("../Clothing/Special/Back.jpg"),require("../Clothing/Special/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "nyxens",
},
{
    "p_id": 109,
    "p_name": "Batch Tshirt 2024-25 (SkyBlue)",
    "p_price": 500,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Blue/BackNyxens.jpg"),require("../Clothing/Blue/FrontNyxens.jpg"),require("../Clothing/Blue/Nyxens.jpg"),require("../Clothing/Blue/2023-25.jpg"),require("../Clothing/Blue/Back.jpg"),require("../Clothing/Blue/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "nyxens",
},
{
    "p_id": 110,
    "p_name": "Batch Tshirt 2024-25 (Maroon)",
    "p_price": 500,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Maroon/NyxensBack.jpg"),require("../Clothing/Maroon/NyxensFront.jpg"),require("../Clothing/Maroon/RightSleeveNyxens.jpg"),require("../Clothing/Maroon/2023-25.jpg"),require("../Clothing/Maroon/Back.jpg"),require("../Clothing/Maroon/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "nyxens",
},
{
    "p_id": 111,
    "p_name": "Batch Tshirt 2024-25 (White)",
    "p_price": 500,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/White/BackNyxens.jpg"),require("../Clothing/White/FrontNyxens.jpg"),require("../Clothing/White/RightSleeveNyxens.jpg"),require("../Clothing/White/2023-25.jpg"),require("../Clothing/White/Back.jpg"),require("../Clothing/White/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "nyxens",
},
{
    "p_id": 112,
    "p_name": "Batch Tshirt 2024-25 (Wild Orchid)",
    "p_price": 500,
    "p_sizes": ["2XS","XS","S","M","L","XL","XXL","3XL","4XL"],
    "p_img": [require("../Clothing/Pink/FrontNyxens.jpg"),require("../Clothing/Pink/BackNyxens.jpg"),require("../Clothing/Pink/RightSleeveNyxens.jpg"),require("../Clothing/Pink/2023-25.jpg"),require("../Clothing/Pink/Back.jpg"),require("../Clothing/Pink/Crest.jpg"),require("../Clothing/SizeChart.jpg")],
    "user_type": "nyxens",
}
]

export default data;