import React, {useState} from 'react';
import { Formik, Form, Field } from 'formik';
import { Alert, Backdrop,TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, Typography} from '@mui/material';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';



const validationSchema = Yup.object({
  Name: Yup.string().required('Full name is required'),
  Phone: Yup.string().required('Phone number is required').matches(
    /^\d{10}$/,
    'Phone number must be 10 digits'
  ),
  Section: Yup.string().required('Section is required').oneOf(
    ['A', 'B', 'C', 'D', 'E','F'],
    'Section must be one of A, B, C, D, E, F'
  ),
  Roll_Number: Yup.string()
        .required('Roll number is required')
        .matches(
          /^(MBA\/09\/\d{3}|MBA\/BA03\/\d{3}|MBA\/HR03\/\d{3}|MBA\/10\/\d{3}|MBA\/BA04\/\d{3}|MBA\/HR04\/\d{3}|MSDSM\/01\/\d{3})$/,
          "Roll number must be of format MBA/09/***, MBA/BA03/***, MBA/HR03/***, MBA/10/***, MBA/BA04/***, MBA/HR04/***, MSDSM/01/***"
        )
        .test(
          'Roll_Number',
          'Roll number out of range',
          (value = '') => {
            const parts = value.split('/');
            if (parts[0] === 'MBA' && parts[2] !== '000') {
              switch (parts[1]) {
                case '09':
                  return parseInt(parts[2], 10) > 0 && parseInt(parts[2], 10) <= 300;
                case 'BA03':
                  return parseInt(parts[2], 10) > 0 && parseInt(parts[2], 10) <= 90;
                case 'HR03':
                  return parseInt(parts[2], 10) > 0 && parseInt(parts[2], 10) <= 50;
                case '10':
                  return parseInt(parts[2], 10) > 0 && parseInt(parts[2], 10) <= 260;
                case 'BA04':
                  return parseInt(parts[2], 10) > 0 && parseInt(parts[2], 10) <= 40;
                case 'HR04':
                  return parseInt(parts[2], 10) > 0 && parseInt(parts[2], 10) <= 50;
                default:
                  return false;
              }
            }
            else if(parts[0] === 'MSDSM' && parts[1] === '01' && parts[2] !== '000'){
              return parseInt(parts[2], 10) > 0 && parseInt(parts[2],10) <= 40
            }
            return false;
          }
        ),
  Hostel: Yup.string().required("Hostel is required")
});


const DataForm = ({cart, apivalues, setApiValues}) => {

  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  

     const postToServerApi = async (values) =>{
      try {
        const response = await axios.post('https://pe9rbc9mg6.execute-api.ap-south-1.amazonaws.com/mascom-test/', values, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log('Response:', response.data);
        navigate('/pay')
      } 
      catch (error) {
            if (error.response) {
                // Server responded with a status other than 2xx
                console.log('Response Error:', error.response.data);
            } else if (error.request) {
                // Request was made but no response was received
                console.log('Request Error:', error.request);
            } else {
                // Something else caused an error
                console.log('Error:', error.message);
            }
      }
    }

    const handleSubmitButton = async (values) => {
      handleOpen()
      let totalPrice =  cart.reduce((sum, item) => sum + item.p_price * item.p_count, 0)
      values.TotalPrice = totalPrice
      values.Cart = cart
      values.transactionId = "XXXXX"
      values.Password = "12345"
      console.log(values)
      await localStorage.setItem("api_values", JSON.stringify(values))

      const {Roll_Number} = values

      try {
        const response = await axios.get(`https://pe9rbc9mg6.execute-api.ap-south-1.amazonaws.com/mascom-test/?Roll_Number=${Roll_Number}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log('Response:', response.data);
        const data = response.data  
        if(data.transactionId !== "XXXXX"){
            setAlert(true)
            handleClose();
        }
        else if(data.transactionId === "XXXXX"){
          postToServerApi(values)
        }
      }
      catch(error){
        if(error.response){
          if(error.response.status === 404){
              postToServerApi(values)
          }
        }
      }
  }

  const hostels = [
    { value: 'Metropolis', label: 'Metropolis' },
    { value: 'Park Inn', label: 'Park Inn' },
    { value: 'Blessings City', label: 'Blessings City' },
  ];

  return (
   <Formik
      initialValues={apivalues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur  
      onSubmit={(values, { setSubmitting, resetForm}) => {
        console.log(values);
        setSubmitting(false);
        handleSubmitButton(values)
        resetForm()
      }}
    >
      {({ values,errors, touched, handleChange, handleBlur, handleSubmit ,isSubmitting, isValid, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2} >
            <Field as={TextField}
              name="Name"
              label="Full Name"
              variant="outlined"
              error={touched.Name && Boolean(errors.Name)}
              helperText={touched.Name && errors.Name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Field as={TextField}
              name="Roll_Number"
              label="Roll Number"
              variant="outlined"
              error={touched.Roll_Number && Boolean(errors.Roll_Number  )}
              helperText={touched.Roll_Number  && errors.Roll_Number }
              onChange={handleChange}
              onBlur={handleBlur}
            ></Field>
            <Field as={TextField}
              name="Phone"
              label="Phone Number"
              variant="outlined"
              error={touched.Phone && Boolean(errors.Phone  )}
              helperText={touched.Phone  && errors.Phone }
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormControl variant="outlined" error={touched.Section && Boolean(errors.Section)}>
            <InputLabel>Section</InputLabel>
             <Field as={Select}
              name="Section"
              label="Section"
              variant="outlined"
              error={touched.Section && Boolean(errors.Section  )}
              helperText={touched.Section  && errors.Section }
              onChange={handleChange}
              onBlur={handleBlur}
            >
                <MenuItem value={"A"}>A</MenuItem>
                <MenuItem value={"B"}>B</MenuItem>
                <MenuItem value={"C"}>C</MenuItem>
                <MenuItem value={"D"}>D</MenuItem>
                <MenuItem value={"E"}>E</MenuItem>
                <MenuItem value={"F"}>F</MenuItem>
            </Field>
            </FormControl>
            <Typography fontSize={15} style={{color:"grey"}}>*For MSDSM, select Section as "A"</Typography>
            <FormControl variant="outlined" error={touched.Hostel && Boolean(errors.Hostel)}>
              <InputLabel>Hostel</InputLabel>
              <Field
                as={Select}
                name="Hostel"
                label="Hostel"
                error={touched.Password && Boolean(errors.Password )}
                helperText={touched.Password  && errors.Password }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Hostel}
              >
                {hostels.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              {touched.Hostel && errors.Hostel && (
                <div style={{ color: 'red', fontSize: '12px' }}>{errors.Hostel}</div>
              )}
            </FormControl>
            <br/>
            <Button type="submit" color="primary" variant="contained" 
            sx={{bgcolor: "#000000", '&:hover': {
              backgroundColor: '#000000',
            }}}
            disabled={(!values.Hostel || isSubmitting || !dirty || !isValid)}>
              Submit
            </Button>
            <br/>
            <br/>
            <Backdrop
                  sx={{ color: '#fff'}}
                  open={open}
                >
                  <CircularProgress color="inherit" />
            </Backdrop>
            {alert && <Alert severity="error" sx={{ mt: 2 }}>
                        You have already placed your order. If you have any queries regarding your order mail us at <b>mascom@iimamritsar.ac.in</b>
                        </Alert>}
          </Box>
        </Form>
      )}
    </Formik> 
  );
};

export default DataForm;
