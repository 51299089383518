// App.jsx
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cart from './components/Pages/Cart'
import Form from './components/Pages/Form'
import Purchase from './components/Pages/Purchase';
import Pay from './components/Pages/Pay';
import Dashboard from './components/Pages/Dashboard'; 
import ComingSoon from './components/ComingSoon';
import UserSelection from './components/Pages/SelectFaction';
import Preloader from './components/Preloader';



      
function App() {
  

  let  [cart, setCart] = useState([])
  let [apivalues, setApiValues] = useState({
      Roll_Number : '',
      Name: '',
      Phone: '',
      Section: '',
      Hostel:'',
    }
  )


  useEffect(() =>{
    async function getData(){
      let cartTemp = await JSON.parse(localStorage.getItem("cart"))
      let apivaluesTemp = await await JSON.parse(localStorage.getItem("api_values"))
      await setCart(cartTemp === null ? [] : cartTemp)
      await setApiValues(apivaluesTemp === null ? {
        Roll_Number : '',
        Name: '',
        Phone: '',
        Section: '',
        Hostel:'',
      } : apivaluesTemp)
    }
    getData();
  },[])


  return (
    <Router>
      <Routes>
        <Route path='/' element={<ComingSoon />}/> 
        {/* <Route path="/" element={<Dashboard />} />
        <Route path="/cart" element={<Cart cart={cart} setCart={setCart} />} />
        <Route path="/form" element={<Form cart={cart} apivalues={apivalues} setApiValues={setApiValues} />} />
        <Route path="/pay" element={<Pay apivalues={apivalues} />} />
        <Route path="/purchase" element={<Purchase />} />
        <Route path="/select" element={<UserSelection />} />  */}
      </Routes>
    </Router>
  );
}

export default App;