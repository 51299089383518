import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Grid, Typography, TextField, Checkbox,Button, FormControlLabel, Box, Alert, Divider, Backdrop, Select, MenuItem, FormControl, InputLabel} from '@mui/material';
import Navbar from '../Navbar'; 
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';


const validationSchema = Yup.object({
    transactionId: Yup.string().required('*UTR Number or Transaction ID is required'),
    agree: Yup.boolean().oneOf([true], '*You must agree to continue')
  });





const Pay = ({apivalues}) => {
    
    const [apiValues, setValues] = useState(null);
    const [qr, setQr] = React.useState(0);

    const qrs = [require('../qr5.jpg'),require('../qr1.jpeg'),require('../qr4.jpg'), require('../qr2.jpg'), require('../qr6.jpg')]

    useEffect(() => {
        async function getValues(){
            let temp = await localStorage.getItem("api_values")
            await setValues(JSON.parse(temp))
        }   
        getValues();
        console.log("these are api values", apiValues)
    },[])

    const [file, setFile] = useState(null);
    const [renamedFile, setRenamedFile] = useState('');
    const [error, setError] = useState('');
    const [fileUrl, setFileUrl] = useState('')

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        const rollNumber = apiValues.Roll_Number
        if (uploadedFile) {
        const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();
        if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
            setError('Only JPG or PNG files are allowed.');
            return;
        }

        setError(''); // Clear any previous error
        if (rollNumber) {
            const newFileName = `${rollNumber}.${fileExtension}`;
            
            // Create a renamed file
            const renamedFile = new File([uploadedFile], newFileName, { type: uploadedFile.type });
            setFile(renamedFile);
            setRenamedFile(newFileName);
        } else {
            setError('Roll number not found in localStorage.');
        }
        }
    };


    const handleUpload = async () => {
        if (!file) {
          setError('Please select a file to upload.');
          return;
        }
    
        try {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64File = reader.result.split(',')[1];
            const body = JSON.stringify({
                "fileContent": base64File,
                "fileType": file.type,
                "fileName": renamedFile
              })
            const response = await axios.post('https://pe9rbc9mg6.execute-api.ap-south-1.amazonaws.com/mascom-test/uploadss', body,{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            setFileUrl(response.data.fileUrl);
            setFile(null);
            setError('');
          };
          reader.readAsDataURL(file);
        } catch (err) {
          console.error("Error uploading file:", err);
          setError('Failed to upload file.');
        }
      };



    const navigate = useNavigate();

    const handlePay = async (values) =>{
        handleUpload();
        console.log("api values,", apiValues)
        apiValues.transactionId =  values.transactionId
        const body = JSON.stringify(apiValues)        
        try{
            const response = await axios.post("https://pe9rbc9mg6.execute-api.ap-south-1.amazonaws.com/mascom-test/",body,{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Response:', response.data);
            if(response.status === 201){
                handleClose()
                navigate('/purchase')                
            }
            }
            catch (error) {
                if (error.response) {
                    // Server responded with a status other than 2xx
                    console.log('Response Error:', error.response.data);
                    alert(error.response.status)          
                } else if (error.request) {
                    // Request was made but no response was received
                    console.log('Request Error:', error.request);
                    alert(error.request)

                } else {
                    // Something else caused an error
                    console.log('Error:', error.message);
                    alert(error.message)

                }
          }
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };

    const handleQr = (event) => {
        setQr(event.target.value);
      };

    const payComoponent = () =>{
        return(
            <React.Fragment>
                <Typography><b>Select a QR Code from below.</b></Typography>
                <Typography sx={{fontSize: 12}}>(If one does not work, try the other. If all the three does not work, please connect with any MasCom Memeber)</Typography>
                <FormControl variant='filled' fullWidth>
                    <InputLabel>Pay To (Select a QR from the dropdown)</InputLabel>
                    <Select
                        name='Pay To'
                        value={qr}
                        label="Qr"
                        onChange={handleQr}
                    >
                        <MenuItem value={0}>Harsh</MenuItem>
                        <MenuItem value={1}>Ruthvik</MenuItem>
                        <MenuItem value={2}>Utsav</MenuItem>
                        <MenuItem value={3}>Hemen</MenuItem>
                        <MenuItem value={4}>Ritesh</MenuItem>

                    </Select>
                </FormControl>
                    <img alt='/' style={{width:"100%",height: 'auto'}} src={qrs[qr]}/>
                
                 <Box>
                    {renamedFile && (
                        <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={renamedFile}
                        InputProps={{
                            readOnly: true,
                        }}
                        />
                    )}
                    <input
                        accept="image/jpeg,image/png"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="raised-button-file">
                        <Button sx={{bgcolor: "#000000", '&:hover': {
                                backgroundColor: '#000000',
                              }}} variant="contained" component="span" fullWidth>
                        Upload Payment Screenshot
                        </Button>
                    </label>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                        </Alert>
                    )}
                </Box>

                <br/>
                <br/>
                <Divider/>
                <br/>
                <br/>

                 <Formik
                        initialValues={{ transactionId: '', agree: false }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handlePay(values);
                        }}
                    >
                        {({ values, isValid, handleChange, handleBlur }) => (
                        <Form>
                            <Box mb={3}>
                            <Field
                                as={TextField}
                                name="transactionId"
                                label="UTR Number or Transaction ID"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!values.transactionId && !!validationSchema.fields.transactionId}
                            />
                            <ErrorMessage component="div"  name="transactionId" style={{ color: 'red' }} />
                            </Box>

                            <Box mb={3}>
                            <FormControlLabel
                                control={
                                <Field
                                    as={Checkbox}
                                    name="agree"
                                    color="primary"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                }
                                label={<span style={{ fontWeight: 'bold' }}>I Agree</span>}
                            />
                            <ErrorMessage name="agree" component="div" style={{ color: 'red' }} />
                            </Box>

                            <Typography sx={{color: "gray"}} variant="body2" component="p">
                                By checking the <b>"I Agree"</b> checkbox, you confirm that:
                                <ol>
                                    <li>You have paid the correct amount for the order.</li>
                                    <li>You have uploaded the appropriate screenshot as proof of payment.</li>
                                    <li>You have correctly entered the UTR Number or UPI ID in the input field.</li>
                                </ol>
                                Please note that if any discrepancies are found in the provided details, your order will be revoked without a refund, and Mascom will not be held liable for any consequences.
                            </Typography>

                            <br/>
                            <br/>   

                            <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleOpen}
                            sx={{bgcolor: "#000000", '&:hover': {
                                backgroundColor: '#000000',
                              }}}
                            fullWidth
                            disabled={!file || !values.agree || !values.transactionId}
                            >
                            Pay
                            </Button>
                            <br />
                            <br />
                        </Form>
                        )}
                    </Formik>
                    </React.Fragment>
        )
    }


    if(apiValues){
    return ( 
    <React.Fragment>
        <Grid container spacing={2} justifyContent ="center">
            <Grid item xs={12}>
                <Navbar/>
            </Grid>
  
            <Grid item 
            xs={12} sm={6} 
            style={{ height: 'calc(100vh - 64px)' , padding : "40px"}}
            justifyContent="center">
                 <Typography variant='h5'>Amount to be Paid:&nbsp;<b>Rs.&nbsp;{apiValues.TotalPrice}</b></Typography>
                 <br/>
                 {payComoponent()}                               
            </Grid> 
        </Grid>
        <Backdrop
        sx={{ color: '#fff'}}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
     );
    }
    else{
        return(
            <></>
        )
    }
}
 
export default Pay;